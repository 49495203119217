<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    width="150"
    height="150"
    class="mx-auto d-block"
    style="margin-top: 20vh"
    :class="{ active: animate }"
  >
    <image
      style="display:none;overflow:visible;"
      width="420"
      height="420"
      xlink:href="photo_2020-08-20_18-01-53.jpg"
      transform="matrix(0.75 0 0 0.75 -15.9055 -15.3228)"
    ></image>
    <path
      fill="#009DE6"
      opacity="0.95"
      class="st0 svg-elem-1"
      d="M276,182.5c-5.5,8.6-16.9,11.2-25.5,5.7c-4.1-2.6-5.7-6.9-8-10.8c-2-3.4-6.1-5.3-7.5-6
	c-1.4-0.7-8.5-2.8-15.7-4.9c-5.8-1.6-11.9,1-14.6,6.3c-1.1,2.2-2.3,4.3-3.5,6.3c-3.1,4.9-2,11.4,2.5,15l0,0c1.2,1,4.1,2.9,7,3
	c8.2,0.3,16.5,1.7,20.4,9.3c4.6,8.9,1.2,19.9-7.8,24.6c-8.9,4.7-19.9,1.2-24.6-7.8c-2.2-4.2-1.4-8.7-1.5-13.1
	c-0.1-3.9-2.6-7.6-3.4-8.8l0,0c-2.6-3.9-7.9-5-11.8-2.3c-11.7,8.3-26,13.3-41.5,13.3h-0.1c-4.8,0-9,3.3-10.2,8
	c-1.3,5.3-2.7,10.8-2.9,12.4c-0.3,1.6-0.5,5,0.8,7.6c3.8,7.3,6.6,15.2,2,22.4c-5.5,8.4-16.7,10.8-25.2,5.4
	c-8.4-5.5-10.8-16.8-5.4-25.2c2.6-4,6.9-5.5,10.7-7.7c3.3-2,5.3-6,6-7.3c0.6-1.1,1.8-4.4,3.2-8c1.8-5-0.6-10.6-5.6-12.6
	c-5.3-2.1-10.2-4.9-14.8-8.1c-3.8-2.7-9-2-11.8,1.6c-0.4,0.6-0.8,1-1,1.3c-0.9,1.4-2.7,4.5-2.6,7.5c0.3,8.5-0.6,17.3-8.2,22
	c-9,5.5-20.7,2.6-26.2-6.3c-5.5-9-2.6-20.7,6.3-26.1c4.2-2.6,9-2,13.5-2.4c4-0.3,7.7-3.2,9-4.2c0.1,0,0.2-0.1,0.2-0.2
	c3.9-3.1,4.8-8.7,2.1-12.8c-7.1-11.1-11.2-24.3-11.2-38.5c0-1.3,0-2.7,0.1-4c0.2-4-2.6-7.5-6.5-8.4c-7.4-1.5-15.1-3.1-16.7-3.3
	c-1.6-0.2-5.1-0.3-7.6,1.2c-7.2,4.2-15.1,7.5-22.6,3.2c-8.8-5.1-11.8-16.4-6.7-25.3c5.1-8.8,16.4-11.8,25.3-6.7
	c4.2,2.4,5.9,6.7,8.4,10.4c2.2,3.3,6.3,5.1,7.7,5.7c1.5,0.7,9.2,2.9,16.3,4.9c3,0.8,6.1-0.7,7.2-3.6c2-5.3,4.7-10.3,7.9-14.9
	c1-1.5,0.1-3.5-1.6-3.6l0,0c-8.1-0.8-16.2-2.6-19.7-10.4c-4.1-9.1,0-19.8,9-23.9c9.1-4.1,19.8-0.1,23.9,9c1.1,2.4,1.2,6.7,1.1,10.1
	c-0.1,1.9,2.1,3.1,3.7,1.9c4.3-3.2,9-6,14-8.1c4.9-2.1,7.5-8.1,5.7-13.2c-1.4-4.2-2.8-8-3.3-9.2c-0.6-1.5-2.3-4.5-4.9-6
	c-7.2-4.1-14-9.3-14-18c0-10.2,8.3-18.5,18.5-18.5c10.2,0,18.5,8.3,18.5,18.5c0,4.8-2.8,8.5-4.8,12.5c-1.8,3.5-1.2,8-1.1,9.5
	c0.1,1.3,0.9,4.9,1.8,8.8c1.3,5.6,6.3,9.6,12.1,9.6c7.1,0,13.9,1,20.4,2.9c6.4,1.9,12.5,4.7,18.1,8.3c3.8,2.5,8.9,1.6,11.9-1.9l0,0
	c1.1-1.3,3.4-4.4,3.6-7.7c0.6-9,2.5-18.2,11-22.3c10-4.8,22.1-0.6,26.9,9.4c4.8,10,0.6,22.1-9.4,26.9c-4.7,2.3-9.7,1.2-14.5,1.2
	c-4.3-0.1-8.5,2.6-9.9,3.5l0,0c-2.6,1.6-3.2,5.1-1.3,7.5c0.2,0.2,0.4,0.5,0.5,0.7c0.4,0.6,0.9,1.2,1.3,1.8c0.7,0.9,1.3,1.9,1.9,2.8
	c7,11,11.1,24,11.1,37.9c0,0.1,0,0.2,0,0.4c0,2.2-0.1,4.5-0.3,6.7c0,0.2,0,0.3,0,0.5v0c-0.1,3.1,2,5.8,5,6.5
	c8.8,2.2,20.9,5.1,23,5.4c1.6,0.2,5.1,0.5,7.7-0.9c7.3-3.9,15.3-6.9,22.7-2.3C278.9,162.5,281.5,173.9,276,182.5z"
    ></path>
    <path
      fill="#ffffff"
      class="st1 svg-elem-2"
      d="M106.3,104h69c0,0,6.7,0,7.3,7.2c0.6,7.2-6.2,7.8-6.2,7.8h-25.1v64c0,0-0.2,5.6-9.2,6.1
	c-9,0.5-10.5-8.7-10.5-8.7v-60h-25.2c0,0-6-2.1-6-8.6C100.4,105.3,106.3,104,106.3,104z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "TracksymSplash",
  created() {
    this.interval = setInterval(() => {
      this.animate = !this.animate;
    }, 2500);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  data() {
    return {
      animate: true,
      interval: null
    };
  }
};
</script>

<style scoped>
svg path.svg-elem-1 {
  fill: transparent;
  -webkit-transition: fill 1.2s linear 1.5s;
  transition: fill 1.2s linear 1.5s;
}

svg.active path.svg-elem-1 {
  fill: rgb(0, 157, 230);
}

svg path.svg-elem-2 {
  fill: transparent;
  -webkit-transition: fill 1.5s linear 2s;
  transition: fill 1.5s linear 2s;
}

svg.active path.svg-elem-2 {
  fill: rgb(255, 255, 255);
}
</style>
